import axios from "axios";
import notie from 'notie';

const instance = axios.create({
  baseURL: "https://api.attarius.com",
});

const appAPI = {
  createTodoList(data) {
    return instance.post("/faucet", data)
  },
}

const input = document.getElementById("input");
const button = document.getElementById("button");
const error = document.getElementById("error-text");
const screen = document.querySelector("body");

if (/Android/i.test(navigator.userAgent)) {
  screen.style.height = `${window.innerHeight}px`;
}

function request(e) {
  e.preventDefault();
  if(grecaptcha.getResponse().length === 0) {
    document.getElementById('g-recaptcha-error').innerHTML = '<span style="color:red;">This field is required.</span>';
    return false;
  } else {
    document.getElementById('g-recaptcha-error').innerHTML = '';
    button.disabled = true;
    button.classList.add('is-loading', 'disabled');
    input.disabled = true;
    appAPI.createTodoList({address: input.value, recaptchaToken: grecaptcha.getResponse()})
      .then(result => {
        if (result.status === 200) {
          notie.alert({type: 'success', text: "Success!", time: 5})
        }
      })
      .catch(error => {
        notie.alert({
          type: 'warning',
          text: error.response.data.message ? error.response.data.message : error.response.data.errors.address,
          time: 5
        })
      })
      .finally(() => {
        button.disabled = false;
        button.classList.remove('is-loading', 'disabled');
        input.value = "";
        input.disabled = false;
        localStorage.setItem('fauset_captcha', null);
        grecaptcha.reset();
      })
  }
}

function emptyInput() {
  if (input.value === "") {
    input.classList.add("error");
    error.innerText = "Input is required";
  }
}

input.addEventListener("keyup", (event) => {
  if (event.target.value !== "") {
    input.classList.remove("error");
    error.innerText = "";
  }
});

input.addEventListener("blur", () => emptyInput());

button.addEventListener('click', (e) => {
  emptyInput();
  if (input.value !== "") request(e);
});
